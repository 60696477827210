.__lines_separare {
    width: 100%;
    height: 20px;
    margin-bottom: 2rem;
    background-size: contain;
    background-position: center;
    background-repeat: repeat-x;
    background-image: url(../../../assets/img/lines.svg);
}

.__section_location {
    position: relative;
    padding: 4rem 0rem;
    overflow: hidden;
}

.__section_location .__content_section {
    width: 90%;
    margin: auto;
    display: flex;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.__section_location .__content_section .__maps {
    position: relative;
    width: 100%;
    height: 450px;
    background-color: #b3b3b3;
}

.__section_location .__content_section .__wrp_mps {
    width: 100%;
}

.__section_location .__content_section .__wrp_mps .__line {
    width: 10%;
    height: 5px;
    min-height: 5px;
    border-radius: 10px;
    margin-bottom: .5rem;
    background-color: var(--secondary);
}

.__section_location .__content_section .__wrp_mps h2 {
    margin-bottom: 1rem;
}

.__section_location .__content_section .__wrp_mps h2 .__p_1 {
    font-size: 2.5rem;
    font-weight: 200;
    color: #b3b3b3;
}

.__section_location .__content_section .__wrp_mps h2 .__p_2 {
    font-size: 4rem;
}

.__section_location .__content_section .__wrp_mps .__contn_msn ul {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.__section_location .__content_section .__wrp_mps .__contn_msn ul li a {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.__section_location .__content_section .__wrp_mps .__contn_msn ul li a .__ico {
    display: grid;
    width: 45px;
    height: 45px;
    place-items: center;
    border-radius: 100px;
    background-color: var(--secondary);
}

.__section_location .__content_section .__wrp_mps .__contn_msn ul li a .__ico .tabler-icon {
    stroke: var(--white);
    stroke-width: 1.5;
}

.__section_location .__content_section .__wrp_mps .__contn_msn ul li a div .__p_scd {
    color: #b3b3b3;
    font-size: .8rem;
}

.__section_location .__content_section .__wrp_mps .__contn_msn ul li a div .__p_pmy {
    color: var(--primary);   
}

@media (min-width: 1024px) {
    .__section_location .__content_section {
        flex-direction: row;
    }

    .__section_location .__content_section .__maps {
        width: 40%;
    }

    .__section_location .__content_section .__wrp_mps {
        width: 40%;
    }
    
}