.__section_clients {
    position: relative;
    padding: 4rem 0rem;
    overflow: hidden;
}

.__section_clients .__title {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.__title h2 {
    position: relative;
    transform: rotate(-4deg);
}

.__title h2 .__title_txt_1 {
    font-size: 3rem;
    font-weight: 200;
    color: #b3b3b3;
}

.__title h2 .__title_txt_2 {
    font-size: 5rem;
    color: var(--secondary);
}

.__title h2 .__trz {
    position: absolute;
    top: -20px;
    right: -60px;
    transform: rotate(45deg);
}


.__slide_clients {
    width: 80%;
    margin: auto;
    padding: 4rem 0rem;
}

.__slide_clients .__card_clients {
    width: 100%;
    height: 250px;
    display: grid;
    overflow: hidden;
    place-items: center;
    border-radius: 10px;
    background-color: #b3b3b3;
    border: 1px solid #E9E9E9;
}
.__slide_clients .__card_clients img {
    width: 100%;
    margin: auto;
}