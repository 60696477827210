.__section_about {
    position: relative;
    padding: 4rem 0rem 2rem 0rem;
    overflow: hidden;
}

.__section_about .__cen_cirl {
    position: absolute;
    width: 100%;
}
.__section_about .__cen_cirl .__circle_ext {
    position: relative;
    width: 1200px;
    height: 1200px;
    margin: auto;
    display: grid;
    place-items: center;
    border-radius: 100%;
    border: 1px solid #b3b3b3;
}

.__section_about .__cen_cirl .__circle_ext .__photo_abo {
    display: none;
    transition: background-image 0.5s ease-in-out;
    box-shadow: 0px 8px 16px 8px rgba(205, 205, 205, 0.2);
}

.__section_about .__cen_cirl .__circle_ext .__photo_1 {
    position: absolute;
    top: 50px;
    left: -40px;
    width: 250px;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
    background-size: cover;
    transform: rotate(4deg);
    background-position: center;
    background-repeat: no-repeat;
}

.__section_about .__cen_cirl .__circle_ext .__photo_2 {
    position: absolute;
    top: 200px;
    right: -40px;
    width: 250px;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
    background-size: cover;
    transform: rotate(-4deg);
    background-position: center;
    background-repeat: no-repeat;
}

.__section_about .__cen_cirl .__circle_ext .__photo_abo img {
    display: none;
}

.__section_about .__cen_cirl .__circle_ext .__circle_int {
    position: relative;
    width: 800px;
    height: 800px;
    margin: auto;
    border-radius: 100%;
    border: 1px solid #b3b3b3;
}

.__section_about .__wrp_title  {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.__section_about .__wrp_title .__cyn_ttl .__bad_ttl {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
}
.__section_about .__wrp_title .__cyn_ttl .__bad_ttl p {
    display: inline-block;
    font-weight: 500;
    color: #0a94fd;
    padding: .9rem 1rem;
    border-radius: 100px;
    background-color: #F3F7FA;
}

.__section_about .__wrp_title .__cyn_ttl .__ttx_ttl {
    position: relative;
}

.__section_about .__wrp_title .__cyn_ttl .__ttx_ttl h1 {
    position: relative;
    font-size: 3rem;
    text-align: center;
    transform: rotate(-4deg);
}

.__section_about .__wrp_title .__cyn_ttl .__ttx_ttl h1 p .__span_light {
    font-weight: 200;
    color: #b3b3b3;
}
.__section_about .__wrp_title .__cyn_ttl .__ttx_ttl h1 p .__span_regular_1 {
    color: var(--primary);
}
.__section_about .__wrp_title .__cyn_ttl .__ttx_ttl h1 p .__span_regular {
    color: var(--secondary);
}

.__section_about .__wrp_title .__cyn_ttl .__ttx_ttl h1 .__trazo {
    position: absolute;
    width: 60%;
    right: -20px;
    bottom: -20px;
    transform: rotate(180deg);
}

@media (min-width: 1024px) {
    .__section_about .__cen_cirl .__circle_ext .__photo_abo {
        display: block;
    }

    .__section_about .__wrp_title .__cyn_ttl .__ttx_ttl h1 .__trazo {
        bottom: 0;
        right: -80px;
    }
}

@media (min-width: 1440px) {
 
    .__section_about .__wrp_title .__cyn_ttl .__ttx_ttl h1 {
        font-size: 4rem;
    }
    
}

.__section_about_ {
    position: relative;
    padding: 0rem 0rem 2rem 0rem;
    overflow: hidden;
}

.__section_about_ .__bento_grd {
    display: grid;
    width: 90%;
    margin: auto;
    gap: 1rem;
    height: 450px;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(4, 1fr);
}

.__section_about_ .__bento_grd .__itm_grd {
    overflow: hidden;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    background-color: #444444;
    background-repeat: no-repeat;
    transition: background-image 0.5s ease-in-out;
}

.__section_about_ .__bento_grd .__itm_grd img {
    display: none;
    transition: background-image 0.5s ease-in-out;
}

.__section_about_ .__bento_grd .__itm_grd_4 {
    grid-column: 1 / span 4;
    grid-row: 4 / span 4;
}
  
.__section_about_ .__bento_grd .__itm_grd_3 {
    grid-column: 3 / span 2;
    grid-row: 1 / span 2;
}
  
.__section_about_ .__bento_grd .__itm_grd_2 {
    grid-column: 3 / span 2;
    grid-row: 3 / span 1;
}
  
.__section_about_ .__bento_grd .__itm_grd_1 {
    grid-column: 1 / span 2;
    grid-row: 1 / span 3;
}

@media (min-width: 1024px) {
    .__section_about_ .__bento_grd .__itm_grd_4 {
        grid-column: 3 / span 2;
        grid-row: 1 / span 4;
    }
      
    .__section_about_ .__bento_grd .__itm_grd_3 {
        grid-column: 2;
        grid-row: 1 / span 2;
    }
      
    .__section_about_ .__bento_grd .__itm_grd_2 {
        grid-column: 2;
        grid-row: 3 / span 2;
    }
      
    .__section_about_ .__bento_grd .__itm_grd_1 {
        grid-column: 1;
        grid-row: 1 / span 4;
    }
}