:root {

    --primary: #041B2E;
    --secondary: #172BDE;

    --bg-secondary: rgb(23, 43, 222, .4);

    --white: #FFFFFF;

    --font-family: "SUSE", sans-serif;

}