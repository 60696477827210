.__card_blog {
    cursor: pointer;
    transition: .4s ease all;
}

.__card_blog:hover {
    transition: .4s ease all;
    transform: translateY(-15px);
}

.__card_blog .__card_blog_img {
    position: relative;
    width: 100%;
    height: 250px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.__card_blog .__card_blog_img .__a_btn_go {
    position: absolute;
    top: 20px;
    right: 20px;
    display: grid;
    width: 60px;
    height: 60px;
    place-items: center;
    border-radius: 100px;
    background-color: var(--white);
}

.__card_blog .__card_blog_img img {display: none;}

.__card_blog .__card_blog_img .__badge_time {
    position: absolute;
    left: 20px;
    bottom: 20px;
    font-size: .8rem;
    border-radius: 100px;
    padding: .5rem .8rem;
    background-color: var(--white);
}

.__card_blog .__card_blog_txt {
    padding: .8rem;
}

.__card_blog .__card_blog_txt h3 {
    color: var(--primary);
    font-weight: bold;
    margin-bottom: .8rem;
}

.__card_blog .__card_blog_txt .__text {
    font-size: .8rem;
    color: #b3b3b3;
    max-height: 100px;
    line-height: 1.5rem;
    text-align: justify;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Limita a 4 líneas */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}