* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-family);
}

html {
    scroll-behavior: smooth;
}

input, select, textarea {
    border: none;
    outline: none;
    font-family: var(--font-family);
}

ul, li, ol {
    list-style: none;
}

label {
    user-select: none;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
}

img {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
}

body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

body::-webkit-scrollbar-thumb {
    background: rgb(4, 27, 46, 1);
    border-radius: 4px;
}

body::-webkit-scrollbar-thumb:hover {
    background: rgb(4, 27, 46, .8);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb:active {
    background-color: rgb(4, 27, 46, .8);
}

body::-webkit-scrollbar-track {
    background: rgb(4, 27, 46, .4);
    border-radius: 4px;
}

/* Cambiamos el fondo cuando esté en active o hover */
.body::-webkit-scrollbar-track:hover,
.body::-webkit-scrollbar-track:active {
  background: rgb(4, 27, 46, .4);
}