.__section_blog {
    position: relative;
    padding: 4rem 0rem;
    overflow: hidden;
}

.__title {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.__title h2 {
    position: relative;
    transform: rotate(-4deg);
}

.__title h2 .__title_txt_1 {
    font-size: 2rem;
    font-weight: 200;
    color: #b3b3b3;
}

.__title h2 .__title_txt_2 {
    font-size: 3rem;
    color: var(--secondary);
}

.__title h2 .__trz {
    position: absolute;
    top: -20px;
    right: -60px;
    transform: rotate(45deg);
}

.__title .__controls_slide {
    display: none;
}


.__section_blog .__slide_blog {
    width: 80%;
    margin: auto;
    padding: 4rem 0rem;
}

.__section_blog .__gride_blog {
    width: 80%;
    margin: auto;
    display: grid;
    padding: 4rem 0rem;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
}

@media (min-width: 1024px) {

    .__title h2 .__title_txt_1 {
        font-size: 3rem;
    }

    .__title h2 .__title_txt_2 {
        font-size: 5rem;
    }

    .__title .__controls_slide {
        display: flex;
        align-items: center;
        gap: 2rem;
    }

    .__title .__controls_slide .__btn_slide {
        display: grid;
        place-items: center;
        padding: .8rem 2rem;
        border-radius: 100px;
        background-color: var(--bg-secondary);
    }

    .__title .__controls_slide .__btn_slide .tabler-icon {
        stroke: var(--secondary);
    }

}