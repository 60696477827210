.__footer {
    width: 100%;
    padding: 1rem 0rem;
}

.__footer .__content_footer {
    width: 90%;
    margin: auto;
    display: grid;
    overflow: hidden;
    padding: 1rem 2rem;
    border-radius: 20px;
    background-color: var(--primary);
    grid-template-rows: 1fr 1fr 100px;
    grid-template-areas: "col1 col1"
                         "col2 col2"
                         "col3 col3";
}

.__footer .__content_footer .__col_A {
    grid-area: col1;
    display: flex;
    padding: 2rem 0rem;
    flex-direction: column;
    justify-content: space-between;
}

.__footer .__content_footer .__col_A .__log a img {
    width: 100%;
}

.__footer .__content_footer .__col_A .__form {
    padding: 0rem 0rem;
}

.__footer .__content_footer .__col_A .__form .__form_group label {
    display: block;
    font-size: .8rem;
    color: var(--white);
    margin-bottom: .5rem;
}

.__footer .__content_footer .__col_A .__form .__form_group .__form_control {
    position: relative;
    width: 100%;
    height: 50px;
    overflow: hidden;
    border-radius: 100px;
    background-color: #1D3041;
}

.__footer .__content_footer .__col_A .__form .__form_group .__form_control .__bnt {
    position: absolute;
    top: 5px;
    display: grid;
    width: 40px;
    height: 40px;
    place-items: center;
    border-radius: 100px;
}

.__footer .__content_footer .__col_A .__form .__form_group .__form_control input {
    width: 100%;
    height: inherit;
    background: none;
    padding: 0rem 60px;
    color: var(--white);
}
.__footer .__content_footer .__col_A .__form .__form_group .__form_control input::placeholder {
    color: #aeaeae;
}
.__footer .__content_footer .__col_A .__form .__form_group .__form_control .__bnt_ico {
    left: 5px;
    background-color: var(--primary);
}
.__footer .__content_footer .__col_A .__form .__form_group .__form_control .__bnt_send {
    right: 5px;
    background-color: var(--secondary);
}

.__footer .__content_footer .__col_A .__form .__form_group .__form_control .__bnt .tabler-icon {
    stroke-width: 1.5;
    stroke: var(--white);
}

.__footer .__content_footer .__col_A .__copy_ p {
    font-size: .8rem;
    color: var(--white);
}

.__footer .__content_footer .__col_A .__copy_ p a {
    color: var(--white);
    font-weight: bold;
}


.__footer .__content_footer .__col_B {
    position: relative;
}

.__footer .__content_footer .__col_B .__a_back {
    position: absolute;
    bottom: -40px;
    display: none;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    place-items: center;
    background-color: var(--white);
}


.__footer .__content_footer .__col_B nav {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.__footer .__content_footer .__col_B nav ul {
    width: 100%;
}


.__footer .__content_footer .__col_B nav ul li span {
    display: block;
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--white);
    margin-bottom: .5rem;
}

.__footer .__content_footer .__col_B nav ul .__sep {
    width: 20%;
    height: 6px;
    border-radius: 10px;
    margin-bottom: 1rem;
    background-color: var(--secondary);
}

.__footer .__content_footer .__col_B nav ul li a {
    display: block;
    color: #dbdbdb;
    padding: .5rem 0rem .5rem 1rem;
}

.__footer .__content_footer .__col_B nav ul li a:hover {
    color: var(--white);
}

.__footer .__content_footer .__col_C {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.__footer .__content_footer .__col_C ul {
    display: flex;
    gap: .5rem;
    align-items: center;
    flex-direction: column;
}

.__footer .__content_footer .__col_C ul li a {
    display: grid;
    width: 40px;
    height: 40px;
    place-items: center;
    border-radius: 100px;
    background-color: var(--secondary);
}

.__footer .__content_footer .__col_C ul li a .tabler-icon {
    stroke: var(--white);
    stroke-width: 1.5;
}


@media (min-width: 1024px) {

    .__footer .__content_footer {
        width: 80%;
        margin: auto;
        display: grid;
        overflow: hidden;
        padding: 1rem 2rem;
        border-radius: 20px;
        background-color: var(--primary);
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 100px;
        grid-template-areas: "col1 col2 col3";
    }

    .__footer .__content_footer .__col_A .__log a img {
        width: 50%;
    }

    .__footer .__content_footer .__col_A .__form {
        padding: 2rem 0rem;
    }

    .__footer .__content_footer .__col_A .__form .__form_group .__form_control {
        width: 60%;
    }

    .__footer .__content_footer .__col_B nav {
        flex-direction: row;
    }

    .__footer .__content_footer .__col_B .__a_back {
        display: grid;
    }

}