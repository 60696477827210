.__section_mision {
    position: relative;
    padding: 4rem 0rem;
    overflow: hidden;
}

.__section_mision .__content_section {
    width: 90%;
    margin: auto;
    display: flex;
    gap: 4rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.__section_mision .__content_section .__slid {
    position: relative;
    width: 90%;
}

.__section_mision .__content_section .__slid .__view_h2 {
    cursor: pointer;
    height: 50px;
    display: grid;
    place-items: center;
    position: absolute;
    color: var(--white);
    padding: 0rem 1rem;
    border-radius: 100px;
    transform: rotate(-15deg);
    transition: .3s ease all;
    border: 4px solid var(--white);
    background-color: var(--primary);
}

.__section_mision .__content_section .__slid .__view_h2_1 {
    bottom: 190px;
    left: 60px;
}
.__section_mision .__content_section .__slid .__view_h2_2 {
    bottom: 140px;
    left: 40px;
}
.__section_mision .__content_section .__slid .__view_h2_3 {
    bottom: 90px;
    left: 20px;
}
.__section_mision .__content_section .__slid .__view_h2_4 {
    bottom: 40px;
}

.__section_mision .__content_section .__slid .__view_h2--active {
    transition: .3s ease all;
    background-color: var(--secondary);
}

.__section_mision .__content_section .__slid .__figure {
    display: grid;
    height: 450px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

.__section_mision .__content_section .__slid .__figure .__figure_itm {
    padding: 20px;
}

.__section_mision .__content_section .__slid .__figure .__figure_itm_1 {
    grid-column: 1;
    grid-row: 1 / span 2;
}
  
.__section_mision .__content_section .__slid .__figure .__figure_itm_2 {
    grid-column: 1;
    grid-row: 3 / span 2;
    background-color: #FFFFFF;
    border-top-right-radius: 20px;
}
  
.__section_mision .__content_section .__slid .__figure .__figure_itm_3 {
    grid-column: 2;
    grid-row: 1 / span 4;
}
  
.__section_mision .__content_section .__slid .__figure .__figure_itm_4 {
    grid-column: 3 / span 2;
    grid-row: 1 / span 4;
    overflow: hidden;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.__section_mision .__content_section .__wrp_msn {
    width: 90%;
}

.__section_mision .__content_section .__wrp_msn .__line {
    width: 10%;
    height: 5px;
    min-height: 5px;
    border-radius: 10px;
    margin-bottom: .5rem;
    background-color: var(--secondary);
}

.__section_mision .__content_section .__wrp_msn h2 {
    margin-bottom: 1rem;
}

.__section_mision .__content_section .__wrp_msn h2 .__p_1 {
    font-size: 2.5rem;
    font-weight: 200;
    color: #b3b3b3;
}

.__section_mision .__content_section .__wrp_msn h2 .__p_2 {
    font-size: 4rem;
}

.__section_mision .__content_section .__wrp_msn .__contn_msn {
    font-size: .9rem;
    color: #858585;
    line-height: 1.5rem;
    text-align: justify;
}

@media (min-width: 1024px) {

    .__section_mision .__content_section {
        width: 90%;
        margin: auto;
        display: flex;
        gap: 4rem;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .__section_mision .__content_section .__slid {
        position: relative;
        width: 40%;
    }

    .__section_mision .__content_section .__wrp_msn {
        width: 40%;
    }

}