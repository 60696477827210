.__section_hero {
    padding: 2rem 0rem;
}

.__section_hero .__content_hero {
    position: relative;
    width: 90%;
    margin: auto;
    display: grid;
    height: 550px;
    overflow: hidden;
    border-radius: 30px;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
}

.__section_hero .__content_hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: .4s ease all;
    background-position: center;
    z-index: -1; /* Mantén la imagen detrás del contenido */
}

.__section_hero .__content_hero_1::before {
    background-image: url(../../../assets/img//banner1.jpg);
}
.__section_hero .__content_hero_2::before {
    background-image: url(../../../assets/img//banner2.jpg);
}
.__section_hero .__content_hero_3::before {
    background-image: url(../../../assets/img//banner3.jpg);
}

.__section_hero .__content_hero .__img_banner {
    display: none;
}

.__section_hero .__content_hero .__bento {
    position: relative;
}

.__section_hero .__content_hero .__bento_gd_1 {
    grid-column: 1 / span 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    background-color: var(--white);
    border-bottom-right-radius: 30px;
}

.__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 {
    display: flex;
    flex-direction: column;
}

.__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 .__text_scondt {
    font-weight: 300;
}

.__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 .__text_accentued {
    font-size: 3rem;
    font-weight: bold;
    transform: rotate(2deg);
    color: var(--secondary);
}

.__section_hero .__content_hero .__bento_gd_2 {
    display: none;
}

.__section_hero .__content_hero .__bento_gd_3 {
    grid-row: 2;
    grid-column: 1;
    display: flex;
    align-items: center;
    background-color: var(--white);
    border-bottom-right-radius: 30px;
}

.__section_hero .__content_hero .__bento_gd_3 h1 {
    display: flex;
    flex-direction: column;
}

.__section_hero .__content_hero .__bento_gd_3 h1 .__text_scondt {
    font-weight: 300;
}

.__section_hero .__content_hero .__bento_gd_3 .__text_primary h1 .__text_accentued {
    font-size: 3rem;
    font-weight: bold;
    transform: rotate(-2deg);
    color: var(--secondary);
}

.__section_hero .__content_hero .__bento_gd_4 {
    grid-row: 2;
    grid-column: 2;
}

.__section_hero .__content_hero .__bento_gd_4 .__rounded_bottom_left {
    display: none;
}
.__section_hero .__content_hero .__bento_gd_5 {
    grid-row: 4;
    grid-column: 2;
    display: flex;
    align-items: center;
    padding: 0rem .5rem;
    border-top-left-radius: 30px;
    background-color: var(--white);
}
.__section_hero .__content_hero .__bento_gd_5 .__badge_info .__content_badge .__ico {
    display: grid;
    width: 30px;
    height: 30px;
    min-width: 30px;
    place-items: center;
}
.__section_hero .__content_hero .__bento_gd_5 .__badge_info .__content_badge .__txt p {
    font-size: .9rem;
    font-weight: bold;
}

.__section_hero .__content_hero .__bento_gd_5 .__badge_info .__btn_primary {
    display: grid;
    padding: .5rem 1rem;
    color: var(--white);
    border-radius: 100px;
    place-content: center;
    background-color: var(--primary);
}

.__section_hero .__content_hero .__bento_gd_6 {
    display: none;
}

.__rounded {
    position: absolute;
}

.__rounded_top_left {
    top: 0;
    left: 0;
}

.__rounded_top_right {
    top: 0;
    right: 0;
}

.__rounded_bottom_left {
    left: 0;
    bottom: 0;
}

.__rounded_bottom_right {
    right: 0;
    bottom: 0;
}

@media (min-width: 768px) {

    .__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 {
        gap: .4rem;
        flex-direction: row;
        align-items: center;
    }

    .__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 .__text_scondt {
        font-size: 2.4rem;
        font-weight: 300;
    }
    
    .__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 .__text_accentued {
        font-size: 4rem;
        font-weight: bold;
        transform: rotate(2deg);
        color: var(--secondary);
    }
    .__section_hero .__content_hero .__bento_gd_3 {
        justify-content: center;
    }
    .__section_hero .__content_hero .__bento_gd_3 h1 {
        flex-direction: column;
    }
    
    .__section_hero .__content_hero .__bento_gd_3 h1 .__text_scondt {
        font-weight: 300;
    }
    
    .__section_hero .__content_hero .__bento_gd_3 .__text_primary h1 .__text_accentued {
        font-size: 5rem;
    }

    .__section_hero .__content_hero .__bento_gd_5 .__badge_info {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .__section_hero .__content_hero .__bento_gd_5 .__badge_info .__content_badge .__ico {
        display: grid;
        width: 40px;
        height: 40px;
        min-width: 40px;
        place-items: center;
    }
    .__section_hero .__content_hero .__bento_gd_5 .__badge_info .__content_badge .__txt {
        font-size: .9rem;
    }
}

@media (min-width: 1024px) {
    .__section_hero .__content_hero {
        position: relative;
        width: 90%;
        margin: auto;
        display: grid;
        height: 550px;
        overflow: hidden;
        border-radius: 30px;
        grid-template-rows: repeat(6, 1fr);
        grid-template-columns: repeat(3, 1fr);
    }

    .__section_hero .__content_hero .__bento_gd_1 {
        grid-column: 1 / span 2;
        grid-row: 1;
        display: flex;
        align-items: center;
        padding: 0rem 2rem;
        background-color: var(--white);
        border-radius: 30px 0px 30px 0px;
    }

    .__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 {
        display: flex;
        gap: .8rem;
        user-select: none;
        align-items: center;
    }

    .__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 .__text_scondt {
        font-weight: 300;
        font-size: 30px;
    }

    .__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 .__text_accentued {
        font-size: 54px;
        font-weight: bold;
        color: var(--secondary);
        transform: rotate(4deg);
    }
    
    .__section_hero .__content_hero .__bento_gd_2 {
        grid-column: 3;
        grid-row: 1 / span 6;
        background-color: transparent;
        border-radius: 0px 30px 30px 0px;
    } 

    .__section_hero .__content_hero .__bento_gd_2 .__controls {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        gap: 1rem;
    }

    .__section_hero .__content_hero .__bento_gd_2 .__controls .__btn {
        display: grid;
        width: 60px;
        height: 60px;
        min-width: 60px;
        place-items: center;
        border-radius: 100px;
        background-color: var(--white);
    }

    .__section_hero .__content_hero .__bento_gd_3 {
        grid-column: 1;
        display: flex;
        padding: 0rem 2rem;
        grid-row: 2 / span 1;
        justify-content: center;
        background-color: var(--white);
        border-radius: 0px 0px 30px 0px;
    }

    .__section_hero .__content_hero .__bento_gd_3 .__text_primary h1 {
        display: flex;
        gap: .8rem;
        user-select: none;
        align-items: center;
    }

    .__section_hero .__content_hero .__bento_gd_3 .__text_primary h1 p {
        font-size: 54px;
        font-weight: bold;
        color: var(--secondary);
        transform: rotate(-4deg);
    }

    .__section_hero .__content_hero .__bento_gd_4 {
        grid-column: 1;
        grid-row: 3 / span 3;
        background-color: transparent;
        border-radius: 30px 0px 0px 30px;
    }

    .__section_hero .__content_hero .__bento_gd_5 {
        grid-column: 1;
        grid-row: 6 / span 4;
        display: flex;
        align-items: center;
        padding: 0rem 1rem;
        background-color: var(--white);
        border-radius: 0px 30px 0px 0px;
    }

    .__section_hero .__content_hero .__bento_gd_5 .__badge_info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .__section_hero .__content_hero .__bento_gd_5 .__badge_info .__content_badge {
        display: flex;gap: 1rem;
    }
    .__section_hero .__content_hero .__bento_gd_5 .__badge_info .__content_badge .__ico {
        display: grid;
        width: 40px;
        height: 40px;
        min-width: 40px;
        place-items: center;
    }
    .__section_hero .__content_hero .__bento_gd_5 .__badge_info .__content_badge .__txt {
        font-weight: bold;
        color: var(--primary);
    }
    .__section_hero .__content_hero .__bento_gd_5 .__badge_info .__btn_primary {
        display: grid;
        height: 50px;
        font-weight: bold;
        padding: 0rem 1rem;
        place-items: center;
        color: var(--white);
        border-radius: 100px;
        background-color: var(--primary);
    }

    .__section_hero .__content_hero .__bento_gd_6 {
        grid-column: 2;
        grid-row: 2 / span 5;
        background-color: transparent;
    }

}

@media (min-width: 1440px) {
    .__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 .__text_scondt {
        font-size: 50px;
    }
    .__section_hero .__content_hero .__bento_gd_1 .__text_primary h1 .__text_accentued,
    .__section_hero .__content_hero .__bento_gd_3 .__text_primary h1 p {
        font-size: 64px;
    }
}

@media (min-width: 2560px) {
    
    .__section_hero .__content_hero {
        width: 60%;
    }
    
}