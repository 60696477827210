.__content_error {
    position: relative;
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    background-color: var(--primary);
}

.__content_error .__img_logo {
    width: 30%;
    display: none;
}

.__content_error .__ctn {
    text-align: center;
}


.__content_error .__ctn h4,
.__content_error .__ctn h1,
.__content_error .__ctn p {
    color: var(--white);
}

.__content_error .__ctn h4 {
    font-size: 2rem;
}
.__content_error .__ctn h1 {
    font-size: 5rem;
}

.__content_error .__ctn p {
    color: #888888;
}

.__content_error .__a_btn {
    display: inline-block;
    padding: 1rem 2rem;
    color: var(--white);
    border-radius: 10px;
    margin-top: 2rem;
    background-color: var(--secondary);
}