.__section_contact {
    position: relative;
    padding: 4rem 0rem;
    overflow: hidden;
}

.__section_contact .__square {
    position: relative;
    width: 90%;
    margin: auto;
    padding: 2rem;
    border-radius: 30px;
    background-color: var(--secondary);
}

.__section_contact .__square .__badge_title_1 {
    position: absolute;
    left: 0;
    top: -50px;
    width: 100%;
    text-align: center;
}

.__section_contact .__square .__badge_title_1 h2 {
    display: inline-block;
    padding: 0rem 2rem;
    border-radius: 20px;
    transform: rotate(-4deg);
    background-color: var(--white);
}

.__section_contact .__square .__badge_title_1 h2 .__txt_1 {
    font-size: 1.5rem;
    font-weight: 200;
    color: #b3b3b3;
}

.__section_contact .__square .__badge_title_1 h2 .__txt_2 {
    font-size: 3rem;
    color: var(--secondary);
}

.__section_contact .__form {
    width: 100%;
    margin: auto;
    padding: 4rem 0rem;
}

.__section_contact .__form .__expl_p {
    text-align: center;
    color: #F2F0F5;
    margin-bottom: 1rem;
}

.__section_contact .__form .__form_group {
    width: 100%;
    margin-bottom: 1rem;
}

.__section_contact .__form .__form_flex {
    display: flex;
    gap: 2rem;
    align-items: center;
    flex-direction: column;
}

.__section_contact .__form .__form_group .__form_control {
    width: 100%;
}

.__section_contact .__form .__form_group .__form_control .__label {
    display: block;
    color: #EEEEEE;
    font-size: .8rem;
    margin-bottom: .5rem;
}

.__section_contact .__form .__form_group .__form_control .__control {
    position: relative;
    width: 100%;
    height: 60px;
    border-radius: 100px;
    background-color: var(--white);
}

.__section_contact .__form .__form_group .__form_control .__control .__icon {
    position: absolute;
    display: grid;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    place-items: center;
}

.__section_contact .__form .__form_group .__form_control .__control .__entry {
    width: 100%;
    height: inherit;
    background: none;
    border-radius: inherit;
    padding: 0rem 2rem 0rem 60px;
}

.__section_contact .__form .__form_group .__form_control .__entry_box {
    width: 100%;
    resize: none;
    padding: 1rem;
    min-height: 100px;
    max-height: 200px;
    border-radius: 20px;
}

.__section_contact .__square .__badge_title_2 {
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 100%;
    text-align: center;
}

.__section_contact .__square .__badge_title_2 .__btn_send {
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--white);
    padding: 1.5rem 4rem;
    border-radius: 100px;
    border: 4px solid var(--white);
    background-color: var(--primary);
}


@media (min-width: 1024px) {

    .__section_contact .__square {
        width: 80%;
    }

    .__section_contact .__square .__badge_title_1 h2 .__txt_1 {
        font-size: 2rem;
    }
    
    .__section_contact .__square .__badge_title_1 h2 .__txt_2 {
        font-size: 4rem;
    }

    .__section_contact .__form {
        width: 60%;
    }

    .__section_contact .__form .__form_flex {
        flex-direction: row;
    }

    .__section_contact .__form .__form_group .__form_control .__entry_box  {
        min-height: 150px;
    }

}