.__section_question {
    position: relative;
    padding: 4rem 0rem;
    overflow: hidden;
}

.__section_question .__title h2 .__title_txt_1 {
    font-size: 2rem;
}

.__section_question .__title h2 .__title_txt_2 {
    font-size: 3rem;
}

.__section_question .__ctn {
    width: 90%;
    margin: auto;
    padding: 2rem 0rem;
}

.__section_question .__ctn .__table {
    width: 100%;
    margin: auto;
    user-select: none;
}

.__section_question .__ctn .__table .__table_row {
    padding: .5rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    transition: .3s ease all;
    background-color: #f6f6f6;
}

.__section_question .__ctn .__table .__table_row .__table_title {
    width: 100%;
    padding: 1rem;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    align-items: center;
    color: var(--ribbon-700);
    background-color: #FFFFFF;
    justify-content: space-between;
}

.__section_question .__ctn .__table .__table_row .__table_title h3 {
    color: var(--secondary);
}

.__section_question .__ctn .__table .__table_row .__table_texts {
    padding: 1rem;
    transition: .3s ease all;
}
.__section_question .__ctn .__table .__table_row .__table_texts p {
    font-size: .9rem;
    line-height: 28px;
}


@media (min-width: 1024px) {

    .__section_question .__title h2 .__title_txt_1 {
        font-size: 3rem;
    }
    
    .__section_question .__title h2 .__title_txt_2 {
        font-size: 5rem;
    }

    .__section_question .__ctn {
        width: 80%;
        margin: auto;
        padding: 2rem 0rem;
    }
    
    .__section_question .__ctn .__table {
        width: 60%;
        margin: auto;
        user-select: none;
    }

}